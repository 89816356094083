import { createAction, props } from '@ngrx/store';
import { Auth } from './auth.state';
import { LoginCredential, Organization } from '@models';

export const UPDATE_AUTH = '[auth state] update auth';
export const UPDATE_SETTING = '[auth state] update setting';
export const LOGIN_START = '[auth state] login start';
export const LOGIN_SUCCESS = '[auth state] login success';
export const LOGOUT_START = '[auth state] logout start';
export const LOGOUT_SUCCESS = '[auth state] logout success';
export const AUTO_LOGIN = '[auth state] auto login';
export const AUTO_LOGIN_SUCCESS = '[auth state] auto login success';
export const INITIALIZE = '[Auth state] initialize';
export const GET_AUTH_USER = '[Auth state] auth user';
export const CHANGE_ORGANIZATION = '[Auth state] change organization';
export const CHANGE_PASSWORD = '[Auth state] change password';
export const LOGIN_ERROR = '[auth state] login error';

export const updateAuth = createAction(
  UPDATE_AUTH,
  props<{ data: Auth; redirect?: boolean }>()
);

export const loginStart = createAction(LOGIN_START, props<LoginCredential>());
export const logoutStart = createAction(
  LOGOUT_START,
  props<{ message?: string }>()
);
export const autoLogout = createAction(AUTO_LOGIN);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const initialize = createAction(INITIALIZE);

export const getAuthUser = createAction(
  GET_AUTH_USER,
  props<{ token?: string; organizationId?: string; redirect?: boolean }>()
);
export const autoLogin = createAction(AUTO_LOGIN);
export const autoLoginSuccess = createAction(
  AUTO_LOGIN_SUCCESS,
  props<{ data: Partial<Auth>; redirect?: boolean }>()
);
export const changeOrganization = createAction(
  CHANGE_ORGANIZATION,
  props<{ id: Organization['id'] }>()
);
export const updateSetting = createAction(
  UPDATE_SETTING,
  props<{
    data: Omit<Auth['setting'], 'header'>;
    message: {
      success: string;
      error: string;
    };
  }>()
);

export const loginError = createAction(
  LOGIN_ERROR,
  props<{ remainingAttempts?: number }>()
);

export const changePassword = createAction(
  CHANGE_PASSWORD,
  props<{
    oldPassword: string;
    newPassword: string;
  }>()
);
